import { setBlockTracking as _setBlockTracking, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _cache[0] || (
    _setBlockTracking(-1),
    _cache[0] = _createElementVNode("div", {
      onClick: _ctx.openYoutubeLink,
      class: "pointer d-flex align-items-center justify-content-center"
    }, [
      _createElementVNode("img", {
        class: "d-md-none",
        width: "40",
        src: _ctx.mobileLogo,
        alt: ""
      }, null, 8, _hoisted_1),
      _createElementVNode("img", {
        class: "d-none d-md-block",
        width: "110",
        src: _ctx.desktopLogo,
        alt: ""
      }, null, 8, _hoisted_2)
    ], 8, ["onClick"]),
    _setBlockTracking(1),
    _cache[0]
  )
}