
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";

import mobileLogo from "@/assets/img/themelooper/Themenlooper_YouTube_mobile.svg";
import desktopLogo from "@/assets/img/themelooper/Themenlooper_YouTube_desktop.svg";

export default defineComponent({
  name: "LogoComponent",
  data() {
    return {
      mobileLogo,
      desktopLogo,
    };
  },
  setup() {
    const toasted: any = getCurrentInstance()?.appContext.config.globalProperties.$toasted;
    const store = useStore();

    const findYoutubeLink = () => store.dispatch("themelooper/findYoutubeLink");

    const youtubeLink = computed(() => store.getters["themelooper/getYoutubeLink"] || []);

    const openYoutubeLink = () => {
      if (youtubeLink.value === "" || !youtubeLink.value) {
        toasted.error("Link is missing");
        return;
      }

      window.open(youtubeLink.value, "_blank");
    };

    onMounted(() => {
      findYoutubeLink();
    });

    return {
      youtubeLink,
      openYoutubeLink,
    };
  },
});
